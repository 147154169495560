import React from 'react'
import { graphql } from 'gatsby'
import { first, unescape } from 'lodash'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Footer from '../components/Footer'
import Stripe from '../components/Stripe'
import Router from '../components/Router'
import PageContentHolder from '../components/PageContentHolder'
import ArticleContent from '../components/ArticleContent'
import PageTitle from '../components/PageTitle'
import Carousel from '../components/Carousel'

class PageTemplate extends React.Component {
  render () {
    const siteMetadata = this.props.data.site.siteMetadata
    const currentPage = this.props.data.wordpressPage
    const cleanedTitle = currentPage.title.replace('&#038;', '&')

    return (
      <Layout>
        { currentPage && currentPage.acf.seo_page_title &&
          <Helmet title={currentPage.acf.seo_page_title} />
        }
        { currentPage && !currentPage.acf.seo_page_title &&
          <Helmet title={`${siteMetadata.title} - ${cleanedTitle}`} />
        }
        <Router
          items={first(this.props.data.allWordpressWpApiMenusMenusItems.edges).node.items}
        />
        <PageTitle title='' prefix />

        <PageContentHolder heading={cleanedTitle} post>
          <ArticleContent post>
            <div>
              <p className='intro-para'>{ currentPage.acf.article_introduction }</p>
              <Carousel width={1 / 1} my={[0, null, 30]} images={currentPage.acf.article_images} post />
              <div dangerouslySetInnerHTML={{ __html: unescape(currentPage.acf.article_content) }} />
            </div>
          </ArticleContent>

          <Stripe bottom />
        </PageContentHolder>

        <Stripe top />
        <Footer />
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query currentPageQuery($id: String!) {
    allWordpressWpApiMenusMenusItems(
      filter: { name: { regex: "/Router/" } },
      sort: { fields: [name], order: DESC}
    ) {
      edges {
        node {
          id
          name
          items {
            title
            url
            type
          }
        }
      }
    }
    wordpressPage(id: { eq: $id }) {
      title
      acf {
        article_introduction
        article_content
        article_images {
            localFile {
                childImageSharp {
                    sizes(
                        maxWidth: 1500
                    ) {
                        ...GatsbyImageSharpSizes_withWebp
                    }
                }
            }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
